<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">
        <ActionButton
          class="mr-3"
          v-b-toggle.vorgaengeDetails-collapse
          title="Ausklappen"
          :iconClass="isCollapseOpen ? 'ki ki-arrow-up' : 'ki ki-arrow-down'"
          @click="toggleCollapse"
        />
        <h3 class="card-label">Vorgänge</h3>
        <b-form-checkbox
          class="mr-1"
          v-model="isBuchungFilterEnabled"
          switch
          style="display: flex; font-size: 14px"
          v-if="activeTabFromRouter === 'vorgaenge'"
        >
          Nur feste Buchungen anzeigen
        </b-form-checkbox>

        <div v-if="activeTabFromRouter === 'teilnehmer'" class="d-flex flex-row font-size-sm ml-6">
          <template v-if="!isLoading">
            <b-dropdown right text="Download Teilnehmerliste" size="sm" variant="success">
              <b-dropdown-item @click="downloadArrayAsExcel('de')">
                <span class="symbol symbol-20 mr-3">
                  <img src="media/svg/flags/162-germany.svg" alt="" />
                </span>
                Deutsch
              </b-dropdown-item>
              <b-dropdown-item @click="downloadArrayAsExcel('en')">
                <span class="symbol symbol-20 mr-3">
                  <img src="media/svg/flags/226-united-states.svg" alt="" />
                </span>
                Englisch
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <b-spinner v-else />
        </div>
        <div v-if="activeTabFromRouter === 'fluege'" class="d-flex flex-row font-size-sm ml-6">
          <template v-if="!isLoading">
            <b-dropdown right text="Download Flugliste" size="sm" variant="success">
              <b-dropdown-item @click="downloadFluglisteAsExcel('de')">
                <span class="symbol symbol-20 mr-3">
                  <img src="media/svg/flags/162-germany.svg" alt="" />
                </span>
                Deutsch
              </b-dropdown-item>
              <b-dropdown-item @click="downloadFluglisteAsExcel('en')">
                <span class="symbol symbol-20 mr-3">
                  <img src="media/svg/flags/226-united-states.svg" alt="" />
                </span>
                Englisch
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <b-spinner v-else />
        </div>
      </div>
      <div class="card-toolbar">
        <ul class="nav nav-tabs nav-bold nav-tabs-line">
          <li class="nav-item">
            <router-link
              :to="{
                path: '',
                query: { ...this.$route.query, vorgangdetailstab: 'vorgaenge' },
              }"
              v-slot="{ href }"
            >
              <a class="nav-link" :class="{ active: activeTabFromRouter === 'vorgaenge' }" :href="href">
                <span class="nav-icon"><i class="far fa-file-alt"></i></span>
                <span class="nav-text">Vorgangsübersicht</span>
              </a>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link
              :to="{
                path: '',
                query: { ...this.$route.query, vorgangdetailstab: 'teilnehmer' },
              }"
              v-slot="{ href }"
            >
              <a class="nav-link" :class="{ active: activeTabFromRouter === 'teilnehmer' }" :href="href">
                <span class="nav-icon"><i class="fas fa-user-check"></i></span>
                <span class="nav-text">Teilnehmerdaten</span>
              </a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{
                path: '',
                query: { ...this.$route.query, vorgangdetailstab: 'fluege' },
              }"
              v-slot="{ href }"
            >
              <a class="nav-link" :class="{ active: activeTabFromRouter === 'fluege' }" :href="href">
                <span class="nav-icon"><i class="fas fa-plane"></i></span>
                <span class="nav-text">Flüge</span>
              </a>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse visible id="vorgaengeDetails-collapse">
      <div class="card-body">
        <div v-if="!isLoading" class="tab-content">
          <div
            v-show="activeTabFromRouter === 'vorgaenge'"
            :class="{ show: activeTabFromRouter === 'vorgaenge' }"
            class="tab-pane active"
          >
            <VorgangTable
              v-if="vorgaenge"
              :items="filteredVorgaengeOverview"
              :isLoading="isLoading"
              :hiddenItemsCount="
                isBuchungFilterEnabled ? this.vorgaenge.length - this.filteredVorgaenge.length : null
              "
              :displayFieldsByKey="[
                'id',
                'reisezeitraum',
                'kunde',
                'zimmer',
                'npx',
                'agentur',
                'brutto',
                'isReleased',
                'stateSlot',
              ]"
              :dokumenteCheckRequired="dokumenteCheckRequired"
              :passdatenCheckRequired="passdatenCheckRequired"
              :reiseterminId="reiseterminObj.id"
              @update-single-item="updateItemInRow"
            >
              <template #stateSlot="row">
                <VorgangStateWidget :state="row.item.blankState" />
              </template>
            </VorgangTable>
          </div>
          <div
            v-show="activeTabFromRouter === 'teilnehmer'"
            :class="{ show: activeTabFromRouter === 'teilnehmer' }"
            class="tab-pane active"
          >
            <div v-if="reiseterminObj">
              <ReleaseprocessKonfigurationBar
                :konfiguration="gueltigeKonfiguration"
              ></ReleaseprocessKonfigurationBar>
            </div>
            <ReiseterminCardVorgaengeTeilnehmer
              v-if="vorgaenge"
              :reisetermin="reiseterminObj"
              :konfiguration="gueltigeKonfiguration"
              :passdatenCheckRequired="passdatenCheckRequired"
              :dokumenteCheckRequired="dokumenteCheckRequired"
              :vorgaenge="filteredVorgaenge"
            ></ReiseterminCardVorgaengeTeilnehmer>
          </div>
          <div
            v-show="activeTabFromRouter === 'fluege'"
            :class="{ show: activeTabFromRouter === 'fluege' }"
            class="tab-pane active"
          >
            <ReiseterminCardVorgaengeFluege
              v-if="vorgaenge"
              :passdatenCheckRequired="passdatenCheckRequired"
              :dokumenteCheckRequired="dokumenteCheckRequired"
              :vorgaenge="filteredVorgaenge"
              @fluege-change-accepted="acceptFluegeChanged($event)"
            ></ReiseterminCardVorgaengeFluege>
          </div>
        </div>
        <div v-if="isLoading" class="tab-content">
          <b-skeleton-table :rows="5" :columns="6"></b-skeleton-table>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  GET_VORGAENGE_WITH_FLIGHTS_TEILNEHMER,
  RESET_TRANSFERPLANUNG_FLUEGE,
} from '@/core/vorgaenge/stores/vorgaenge.module';

import ReiseterminCardVorgaengeTeilnehmer from '@/components/produkte/reisetermine/reisetermin-card-vorgaenge-teilnehmer.vue';
import ReiseterminCardVorgaengeFluege from '@/components/produkte/reisetermine/reisetermin-card-vorgaenge-fluege.vue';
import { format, parse } from 'date-fns';
import VorgangTable from '@/components/vorgaenge/vorgang-table.vue';
import VorgangStateWidget from '@/components/vorgaenge/vorgang-state-widget.vue';
import ActionButton from '@/components/common/action-button.vue';
import ReleaseprocessKonfigurationBar from '@/components/vorgaenge/releaseprocess-konfiguration-bar.vue';

import { mapState } from 'vuex';
import { utils, read, writeFileXLSX } from 'xlsx';

export default {
  name: 'ReiseterminViewCardVorgaengeReleaseProcessComponent',
  components: {
    ReiseterminCardVorgaengeTeilnehmer,
    ReiseterminCardVorgaengeFluege,
    VorgangTable,
    VorgangStateWidget,
    ActionButton,
    ReleaseprocessKonfigurationBar,
  },
  props: {
    reiseterminObj: { type: Object },
    gueltigeKonfiguration: { type: Object },
  },
  data() {
    return { isBuchungFilterEnabled: false, isCollapseOpen: true };
  },
  async mounted() {
    if (Array.isArray(this.vorgaenge) && this.vorgaenge.length <= 0 && !this.isLoading) {
      this.getTeilnehmerAndFluege(this.reiseterminObj.id);
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.vorgaenge.isLoading,
      isInitialLoading: state => state.vorgaenge.isInitialLoading,
      vorgaenge: state => state.vorgaenge.vorgaenge,
    }),
    teilnehmerListenDeutschEnglisch() {
      const sortedVorgaenge = [...this.vorgaenge].sort((a, b) => {
        return new Date(a.reisedatum) - new Date(b.reisedatum);
      });
      const firstVorgang = sortedVorgaenge[0];
      const lastVorgang = sortedVorgaenge[sortedVorgaenge.length - 1];
      return [
        `https://skr3-metabase.azurewebsites.net/question/276-participants-teilnehmerliste-englisch?from=${firstVorgang?.reisedatum}&until=${lastVorgang?.reisedatum}&reisekuerzel=${this.reiseterminObj.reise.reisekuerzel}`,
        `https://skr3-metabase.azurewebsites.net/question/258-teilnehmerliste?from=${firstVorgang?.reisedatum}&until=${lastVorgang?.reisedatum}&reisekuerzel=${this.reiseterminObj.reise.reisekuerzel}`,
      ];
    },
    filteredVorgaengeOverview() {
      return this.isBuchungFilterEnabled
        ? this.vorgaenge.filter(vorgang => vorgang.blankState === 'Buchung' || vorgang.isReleased)
        : this.vorgaenge;
    },
    filteredVorgaenge() {
      return this.vorgaenge.filter(
        vorgang => vorgang.blankState === 'Buchung' || vorgang.blankState === 'Option' || vorgang.isReleased
      );
    },
    activeTabFromRouter() {
      return this.$route.query.vorgangdetailstab || 'vorgaenge';
    },
    dokumenteCheckRequired() {
      return this.gueltigeKonfiguration?.dokumente;
    },
    passdatenCheckRequired() {
      return this.gueltigeKonfiguration?.passdaten;
    },
  },
  watch: {
    async reiseterminObj() {
      this.getTeilnehmerAndFluege(this.reiseterminObj.id);
    },
  },
  methods: {
    toggleCollapse() {
      this.isCollapseOpen = !this.isCollapseOpen;
    },
    async updateItemInRow() {
      this.$emit('update-single-item');
    },
    downloadFluglisteAsExcel(language = 'de') {
      const filteredVorgaenge = this.vorgaenge.filter(
        vorgang =>
          !vorgang.stornierungsDatum &&
          vorgang?.blankState != 'StornierteOption' &&
          vorgang?.blankState != 'StornierteBuchung'
      );
      const flugListe = filteredVorgaenge
        .map(vorgang => {
          return vorgang.fluege
            .filter(flug => flug.abflughafen !== null && flug.ankunftsflughafen !== null)
            .map(flug => {
              return [
                vorgang.id,
                vorgang.pnr?.join(', '),
                vorgang.kunde === null
                  ? vorgang.teilnehmer[0]?.name
                  : vorgang.kunde.master.vorname + ' ' + vorgang.kunde.master.nachname,
                flug.abflughafen + ' - ' + flug.ankunftsflughafen,
                flug.flugnummer,
                format(new Date(flug.abflugdatum), 'dd.MM.yyyy'),
                format(new Date(flug.abflugdatum), 'HH:mm'),
                format(new Date(flug.ankunftsdatum), 'HH:mm'),
                vorgang.airlinePnr.join(', '),
                vorgang.blankState,
              ];
            });
        })
        .flat()
        .sort((a, b) => {
          const abflugDateA = parse(a[5] + ' ' + a[6], 'dd.MM.yyyy HH:mm', new Date());
          const abflugDateB = parse(b[5] + ' ' + b[6], 'dd.MM.yyyy HH:mm', new Date());
          return abflugDateA < abflugDateB ? -1 : abflugDateA == abflugDateB ? 0 : 1;
        });

      console.log(flugListe);

      flugListe.unshift([
        language == 'de' ? 'Vorgangsnummer' : 'Booking Id',
        language == 'de' ? 'PNR' : 'PNR',
        language == 'de' ? 'Kunde' : 'Customer',
        language == 'de' ? 'Flugstrecke' : 'Flight route',
        language == 'de' ? 'Flugnummer' : 'Flight number',
        language == 'de' ? 'Abflugdatum' : 'Departure date',
        language == 'de' ? 'Abflugzeit' : 'Departure time',
        language == 'de' ? 'Ankunftszeit' : 'Arrival time',
        language == 'de' ? 'Fluggastdatensatz' : 'Passenger Name Record',
        language == 'de' ? 'Status' : 'State',
      ]);
      // Create a new workbook
      let wb = utils.book_new();

      // Convert the array to a worksheet
      let ws = utils.aoa_to_sheet(flugListe);

      // Add the worksheet to the workbook
      utils.book_append_sheet(wb, ws, language == 'de' ? 'Teilnehmerliste' : 'Participants');

      // Write the workbook to a binary string and initiate download
      writeFileXLSX(
        wb,
        `${this.reiseterminObj.reiseterminkuerzel}_${language == 'de' ? 'Flugliste' : 'Flightlist'}.xlsx`
      );
    },
    downloadArrayAsExcel(language = 'de') {
      let currentRow = 0;
      const teilnehmerList = this.vorgaenge
        .filter(vorgang => !vorgang.stornierungsDatum && vorgang?.blankState != 'StornierteOption')
        .reduce((acc, cur) => {
          cur.teilnehmer.forEach(teilnehmer => {
            acc[currentRow] = [
              cur?.id,
              teilnehmer?.name,
              cur.teilnehmer?.length || 0,
              teilnehmer?.geburtsdatum?.value,
              teilnehmer?.geburtsort?.value,
              teilnehmer?.geburtsland?.value,
              teilnehmer?.nationalitaet?.value,
              teilnehmer?.geschlecht?.value,
              teilnehmer?.passAusstellungsdatum?.value,
              teilnehmer?.passGueltigkeitsdatum?.value,
              teilnehmer?.passName?.value,
              teilnehmer?.passLand?.value,
              teilnehmer?.passNummer?.value,
              teilnehmer?.passOrt?.value,
              teilnehmer?.passArt?.value,
              cur.blankState,
            ];
            currentRow++;
          });
          return acc;
        }, []);
      teilnehmerList.unshift([
        language == 'de' ? 'Vorgangsnummer' : 'Booking Id',
        'Name',
        language == 'de' ? 'Anzahl Teilnehmer' : 'Number of Participants',
        language == 'de' ? 'Geburtsdatum' : 'Date of Birth',
        language == 'de' ? 'Geburtsort' : 'Place of origin',
        language == 'de' ? 'Geburtsland' : 'Country of origin',
        language == 'de' ? 'Nationalität' : 'Nationality',
        language == 'de' ? 'Geschlecht' : 'Sex',
        language == 'de' ? 'Pass Ausstellungsdatum' : 'Passport date of issuing',
        language == 'de' ? 'Pass Gültigkeitsdatum' : 'Passport date of expiration',
        language == 'de' ? 'Pass Name' : 'Pass Name',
        language == 'de' ? 'Pass Land' : 'Pass Country',
        language == 'de' ? 'Pass Nummer' : 'Pass Number',
        language == 'de' ? 'Pass Ort' : 'Pass City',
        language == 'de' ? 'Pass Art' : 'Pass Type',
        language == 'de' ? 'Status' : 'State',
      ]);
      // Create a new workbook
      let wb = utils.book_new();

      // Convert the array to a worksheet
      let ws = utils.aoa_to_sheet(teilnehmerList);

      // Add the worksheet to the workbook
      utils.book_append_sheet(wb, ws, language == 'de' ? 'Teilnehmerliste' : 'Participants');

      // Write the workbook to a binary string and initiate download
      writeFileXLSX(
        wb,
        `${this.reiseterminObj.reiseterminkuerzel}_${
          language == 'de' ? 'Teilnehmerliste' : 'Participants'
        }.xlsx`
      );
    },
    async acceptFluegeChanged($event) {
      this.$store.dispatch(RESET_TRANSFERPLANUNG_FLUEGE, { id: $event.flugData.vorgangId }).then(() => {
        this.getTeilnehmerAndFluege(this.reiseterminObj.id);
      });
    },
    async getTeilnehmerAndFluege(reiseterminId) {
      return this.$store.dispatch(GET_VORGAENGE_WITH_FLIGHTS_TEILNEHMER, {
        reiseterminId: reiseterminId,
      });
    },
  },
};
</script>
